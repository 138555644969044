"use strict";
/*
| Developed by Starton
| Filename : card.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.card = card;
/*
|--------------------------------------------------------------------------
| Card
|--------------------------------------------------------------------------
*/
function card(theme) {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    boxShadow: 'none',
                    borderRadius: 0,
                    zIndex: 0, // Fix Safari overflow: hidden with border radius
                    border: `1px solid ${theme.palette.divider}`,
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: { variant: 'h4' },
                subheaderTypographyProps: {
                    variant: 'body2',
                    marginTop: theme.spacing(0.5),
                },
            },
            styleOverrides: {
                root: {
                    padding: theme.spacing(3, 3, 0),
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(3),
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(0, 3, 2),
                },
            },
        },
    };
}
