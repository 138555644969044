"use strict";
/*
| Developed by Starton
| Filename : string.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.capitalize = capitalize;
exports.slugify = slugify;
exports.generateRandomString = generateRandomString;
exports.truncate = truncate;
exports.truncateHEXAddress = truncateHEXAddress;
exports.truncateCID = truncateCID;
/**
 * Capitalize the first letter of a string
 * @param str - The string to capitalize
 * @returns The capitalized string
 */
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
/**
 * Slugify a string
 * @param str - The string to slugify
 * @returns The slugified string
 */
function slugify(str) {
    return str
        .trim() // trim leading/trailing white space
        .toLowerCase() // convert string to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
}
/**
 * Generate a random string
 * @param length - The length of the string to generate
 * @returns The generated string
 */
function generateRandomString(length) {
    return Math.random()
        .toString(36)
        .substring(2, length + 2);
}
/**
 * Truncate a string without cutting words
 * @param str - The string to truncate
 * @param maxLength - The maximum length of the string
 * @returns The truncated string
 */
function truncate(str, maxLength) {
    if (str.length <= maxLength)
        return str;
    const truncated = str.substring(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');
    if (lastSpaceIndex === -1)
        return truncated + '...';
    return truncated.substring(0, lastSpaceIndex) + '...';
}
/**
 * Truncate a HEX address to show only the start and end characters
 * @param address - The HEX address to truncate
 * @param startCount - Number of characters to show at start (default: 4)
 * @param endCount - Number of characters to show at end (default: 4)
 * @returns The truncated address
 */
function truncateHEXAddress(address, startCount = 4, endCount = 4) {
    const regex = new RegExp(`^(0x[a-zA-Z0-9]{${startCount}})[a-zA-Z0-9]+([a-zA-Z0-9]{${endCount}})$`);
    const match = address.match(regex);
    if (!match)
        return address;
    return `${match[1]}…${match[2]}`;
}
/**
 * Truncate a CID to show only the start and end characters
 * @param cid - The CID to truncate
 * @param startCount - Number of characters to show at start (default: 4)
 * @param endCount - Number of characters to show at end (default: 4)
 * @returns The truncated CID
 */
function truncateCID(cid, startCount = 4, endCount = 4) {
    const regex = new RegExp(`^([a-zA-Z0-9]{${startCount}})[a-zA-Z0-9]+([a-zA-Z0-9]{${endCount}})$`);
    const match = cid.match(regex);
    if (!match)
        return cid;
    return `${match[1]}…${match[2]}`;
}
