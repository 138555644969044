"use strict";
/*
| Developed by Starton
| Filename : select.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.select = select;
/*
|--------------------------------------------------------------------------
| Select
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function select(_theme) {
    return {
        MuiSelect: {
            styleOverrides: {
                icon: {
                    right: 10,
                    width: 18,
                    height: 18,
                    top: 'calc(50% - 9px)',
                },
            },
        },
        MuiNativeSelect: {
            styleOverrides: {
                icon: {
                    right: 10,
                    width: 18,
                    height: 18,
                    top: 'calc(50% - 9px)',
                },
            },
        },
    };
}
