"use strict";
/*
| Developed by Starton
| Filename : appbar.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.appBar = appBar;
/*
|--------------------------------------------------------------------------
| AppBar
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function appBar(_theme) {
    return {
        MuiAppBar: {
            defaultProps: {
                color: 'transparent',
            },
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
    };
}
