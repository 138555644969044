/*
| Developed by Starton
| Filename : useDebounce.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDebounceLoading = exports.useDebounce = void 0;
const react_1 = require("react");
/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
const useDebounce = ({ value, delay = 500 }) => {
    const [debouncedValue, setDebouncedValue] = (0, react_1.useState)(value);
    (0, react_1.useEffect)(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return debouncedValue;
};
exports.useDebounce = useDebounce;
const useDebounceLoading = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = (0, react_1.useState)(value);
    const [loading, setLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            setDebouncedValue(value);
            setLoading(false);
        }, delay || 500);
        return () => {
            setLoading(false);
            clearTimeout(timer);
        };
    }, [value, delay]);
    return { debouncedValue, loading };
};
exports.useDebounceLoading = useDebounceLoading;
