"use strict";
/*
| Developed by Starton
| Filename : button-group.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonGroup = buttonGroup;
const styles_1 = require("@mui/material/styles");
const ButtonGroup_1 = require("@mui/material/ButtonGroup");
/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
/*
|--------------------------------------------------------------------------
| ButtonGroup
|--------------------------------------------------------------------------
*/
function buttonGroup(theme) {
    const rootStyles = (ownerState) => {
        const inheritColor = ownerState.color === 'inherit';
        const containedVariant = ownerState.variant === 'contained';
        const outlinedVariant = ownerState.variant === 'outlined';
        const textVariant = ownerState.variant === 'text';
        const softVariant = ownerState.variant === 'soft';
        const horizontalOrientation = ownerState.orientation === 'horizontal';
        const verticalOrientation = ownerState.orientation === 'vertical';
        const defaultStyle = {
            [`& .${ButtonGroup_1.buttonGroupClasses.grouped}`]: {
                padding: theme.spacing(1),
                '&:not(:last-of-type)': {
                    ...(!outlinedVariant && {
                        borderStyle: 'solid',
                        ...(inheritColor && {
                            borderColor: (0, styles_1.alpha)(theme.palette.grey[500], 0.32),
                        }),
                        // HORIZONTAL
                        ...(horizontalOrientation && {
                            borderWidth: '0px 1px 0px 0px',
                        }),
                        // VERTICAL
                        ...(verticalOrientation && {
                            borderWidth: '0px 0px 1px 0px',
                        }),
                    }),
                },
            },
        };
        const colorStyle = COLORS.map((color) => ({
            [`& .${ButtonGroup_1.buttonGroupClasses.grouped}`]: {
                '&:not(:last-of-type)': {
                    ...(!outlinedVariant && {
                        ...(ownerState.color === color && {
                            // CONTAINED
                            ...(containedVariant && {
                                borderColor: (0, styles_1.alpha)(theme.palette[color].dark, 0.48),
                            }),
                            // TEXT
                            ...(textVariant && {
                                borderColor: (0, styles_1.alpha)(theme.palette[color].main, 0.48),
                            }),
                            // SOFT
                            ...(softVariant && {
                                borderColor: (0, styles_1.alpha)(theme.palette[color].dark, 0.24),
                            }),
                        }),
                    }),
                },
            },
        }));
        const disabledState = {
            [`& .${ButtonGroup_1.buttonGroupClasses.grouped}`]: {
                [`&.${ButtonGroup_1.buttonGroupClasses.disabled}`]: {
                    '&:not(:last-of-type)': {
                        borderColor: theme.palette.action.disabledBackground,
                    },
                },
            },
        };
        return [defaultStyle, ...colorStyle, disabledState];
    };
    return {
        MuiButtonGroup: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
