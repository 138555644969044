/*
| Developed by Starton
| Filename : AppContainer.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/
'use client';
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppContainer = AppContainer;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const constants_1 = require("../../constants");
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
function AppContainer({ children, drawerWidth, ...containerProps }) {
    return ((0, jsx_runtime_1.jsx)(material_1.Box, { flexGrow: 1, maxWidth: `calc(100vw - ${drawerWidth || constants_1.NAV_CONFIG.drawerWidth}px)`, ...containerProps, children: children }));
}
